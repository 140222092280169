<script lang="ts" setup>
    import {computed} from 'vue';
    import useCpBem from '~/composables/useCpBem';
    import CpText from '~/components/cp/CpText/CpText.vue';
    import {useColors, toCssVariable as toCss} from '~/composables/useCpType';
    import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
    import type {ICpRouteLocationNamedRaw} from '~/utils/services/screens';

    const toCssVariable = toCss;
    const props = withDefaults(
        defineProps<{
            kind?: 'info'|'success'|'warning'|'critical'
            closeIcon?: boolean
            action?: boolean
            actionText?: string
            textAlign?: 'center' | 'rigth' | 'left' | 'justify'
            // Use this prop for actions with redirects, this'll improve SEO
            to?: ICpRouteLocationNamedRaw | string | null
        }>(), {
            kind: 'info',
            closeIcon: true,
            action: false,
            actionText: undefined,
        },
    );

    defineEmits<{
        (e: 'alert:close'): null
        (e: 'alert:click'): void
    }>();

    const colorPalette = computed(() => {
       return props.kind === 'info' ?
useColors('primary', {hollow: true}) :
           useColors(props.kind, {hollow: true});
    });

    const icon = computed(() => {
        switch (props.kind) {
            case 'critical':
                return 'error';
            case 'success':
                return 'check_circle';
            default:
                return props.kind;
        }
    });

    const {b, em} = useCpBem('cp-alert');
</script>
<template>
    <div :class="b">
        <CpIconStandard :icon="icon" :type="colorPalette.color" />
        <div :class="em(`text-container`, { textAlign: !!textAlign })">
            <div>
                <slot />
            </div>
            <CpNuxtLink v-if="action && to" :to="to" data-testid="alert-link">
                <CpText
                    :type="kind === 'info' ? colorPalette.color : colorPalette.activeColor"
                    variant="body-link"
                >
                    {{ actionText }}
                </CpText>
            </CpNuxtLink>
            <CpText v-if="action && !to"
                data-testid="alert-link"
                :type="kind === 'info' ? colorPalette.color : colorPalette.activeColor"
                variant="body-link"
                @click="$emit('alert:click')"
            >
                {{ actionText }}
            </CpText>
        </div>
        <CpIconStandard
            v-if="closeIcon"
            icon="close"
            :type="colorPalette.color"
            @click="$emit('alert:close')"
        />
    </div>
</template>
<style scoped lang="scss">
    .cp-alert {
        border: 1px solid v-bind("toCssVariable(colorPalette.color)");
        border-radius: $cp-border-radius;
        background-color: v-bind("toCssVariable(colorPalette.active)");
        padding: $cp-gutter;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $cp-gutter;
        &__text-container {
            width: auto;
            display: flex;
            flex-direction: column;
            line-height: 0;
            gap: $cp-gutter-small;
            &--text-align {
              text-align: v-bind("props.textAlign");
            }
        }
    }
</style>
